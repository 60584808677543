:root {
  --link: #61dafb;
  --background-tan: #cfc6b3;
  --accent-brown: #4d3529;
}

label {
  font-size: 1rem;
}
input { 
  font-size: 0.95rem;
}
input[readonly] {
    cursor: default;
    background-color: rgba(234, 229, 229, 0.85);
    border: 1px solid var(--accent-brown);
    border-radius: 2px;
}

/* input[type="date"] {

} */

button {
  background-color: var(--accent-brown);
  color: white;
  font-weight: 700;
  border: var(--accent-brown);
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}
button:hover {
  transform: scale(1.048);
  transition: transform 0.5s ease-in-out;
  backface-visibility: hidden;
}


.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background-color: var(--background-tan);
  color: var(--accent-brown);
}

.cost-container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.total-cost {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-end;
  margin: 1rem 4rem;
  gap: 1rem;
  padding: 10px;
  border: 2px solid var(--accent-brown);
  border-radius: 3px;
}
.total-cost label {
  font-weight: 700;
}

.cube-calc {
  font-size: 1.1rem;
}

.submit-order {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-els {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.contact-btn {
  height: 35px;
  width: 91px;
  margin: 0 5px 0 0;
}
.external:link, :visited {
  color: var(--accent-brown);
}
.external:hover {
  color: white;
}

.attributions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.attributions p {
  margin: 0;
  font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
  .total-cost{
    margin-top: 20px;
    align-self: center;
  }

  .contact {
    margin: 0 20px;
  }
}

