input {
    height: 25px;
}

h1 {
    text-align: center;
}

textarea {
    min-height: 183px;
    width: 250px;
}

.sheet-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.form-meta-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 2px solid var(--accent-brown);
}

.order-number-container {
    position: absolute;
}

.form-field  {
    justify-content: flex-end;
    padding: 20px;
    margin: 40px 10px;
}

.col {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

#fiuo {
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .form-meta-info {
        justify-content: center;
    }
}




