.base-table {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    flex-direction: column;
    border: 2px solid var(--accent-brown);
    margin: 20px;
    padding: 20px;
}

.base-section {
    gap: 1rem;
}
.base-picker-section {
    display: flex;
    justify-content: space-around;
}
.base-features {
    text-align: center;
}

.base-description {
    min-height: 51px;
    margin-bottom: 0;
    font-size: 16px;
    color: black;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .base-description {
        width: 400px;
        min-height: 54px;
    }
}