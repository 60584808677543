.pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.pricing-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 6px;
}

.confirm-btn {
    height: 40px;
    margin: 14px 0 0 7px;
}
