.model-table {
    border: 2px solid var(--accent-brown);
    margin: 20px;
    padding: 20px;
}

.model-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.model-description {
    width: 176px;
    min-height: 38px;
    font-size: 16px;
    color: black;
    font-weight: bold;
}

.model-validation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin: 20px;
    padding: 20px;
    border: 2px solid var(--accent-brown);
    border-radius: 3px;
}
.model-validation h4 {
    margin: 20px;
    text-align: center;
}
#validity-note {
    font-size: 0.8rem;
    max-width: 367px;
}

/* input[readonly] {
    cursor: default;
    background-color: rgb(172, 172, 172);
    border: none;
} */

@media screen and (max-width: 600px) {
    .model-list {
        width: 400px;
    }
}
